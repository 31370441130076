<template lang="">
  <div class="w-full shadow-lg sticky top-0 bg-white z-[100]">
    <div class="w-full h-full max-w-7xl mx-auto">
      <!-- Navigation -->
      <div class="bg-white pl-4 md:px-4 border-b-2">
        <div
          class="h-[80px] md:h-[85px] flex flex-row items-center justify-between gap-4 max-w-screen-2xl mx-auto"
        >
          <img src="../assets/logo.png" alt="" class="w-[200px]" />
          <ul
            v-if="this.isAuthenticated != true"
            class="hidden md:flex flex-row gap-4 md:gap-7 text-xs md:text-sm cursor-pointer"
          >
            <li @click="scrollPosition('about-content', $event)" class="p-2">
              About us
            </li>
            <li @click="scrollPosition('faq-content', $event)" class="p-2">
              FAQs
            </li>
            <li @click="scrollPosition('enquiry-content', $event)" class="p-2">
              Enquiry
            </li>
          </ul>
          <div v-if="!this.isAuthenticated" class="flex flex-row items-center">
            <div class="hidden md:flex items-center">
              <a
                href="https://api.whatsapp.com/send/?phone=917005987005&text&app_absent=0"
                target="_blank"
              >
                <img
                  src="../assets/whatsapp-icon.png"
                  alt=""
                  width="35"
                  class="mr-2"
                />
              </a>
              <div
                class="bg-primary px-4 py-2 rounded-lg mr-5 font-semibold text-sm text-white"
              >
                <a href="tel:+919594556845" target="_blank">
                  Call Us @ +91 95945 56845
                </a>
              </div>
            </div>
            <div>
              <router-link
                v-if="this.isAuthenticated != true"
                :to="{ name: 'Login' }"
              >
                <button
                  class="bg-primary px-4 py-2 rounded-lg font-semibold text-sm text-white"
                >
                  Login
                </button>
              </router-link>
            </div>
          </div>
          <div v-else class="hidden sm:block">
            <div class="flex items-center gap-4">
              <template
                v-if="this.userInfo.data.user.Permission_access == 'student'"
              >
                <router-link
                  :to="{ name: 'DashboardView' }"
                  class="flex gap-2 items-center"
                >
                  <img
                    v-if="this.userProfiileInfo[0].user_data_filed[0].profile"
                    :src="this.userProfiileInfo[0].user_data_filed[0].profile"
                    class="w-14 h-14 p-1 rounded-full border"
                  />
                  <img
                    v-else
                    src="../assets/man.png"
                    class="w-10 p-1 rounded-full border"
                  />
                  <h3 class="text-lg font-semibold capitalize">
                    {{ this.userProfiileInfo[0].display_name }}
                  </h3>
                </router-link>
              </template>
              <template
                v-else-if="this.userInfo.data.user.Permission_access == 'admin'"
              >
                <router-link
                  :to="{ name: 'adminDashboard' }"
                  class="flex gap-2 items-center"
                >
                  <img
                    v-if="this.userProfiileInfo[0].user_data_filed[0].profile"
                    :src="this.userProfiileInfo[0].user_data_filed[0].profile"
                    class="w-14 h-14 p-1 rounded-full border"
                  />
                  <img
                    v-else
                    src="../assets/man.png"
                    class="w-10 p-1 rounded-full border"
                  />
                  <h3 class="text-lg font-semibold capitalize">
                    {{ this.userProfiileInfo[0].display_name }}
                  </h3>
                </router-link>
              </template>
              <template
                v-else-if="this.userInfo.data.user.Permission_access == 'cp'"
              >
                <router-link
                  :to="{ name: 'cpDashboard' }"
                  class="flex gap-2 items-center"
                >
                  <img
                    v-if="this.userProfiileInfo[0].user_data_filed[0].profile"
                    :src="this.userProfiileInfo[0].user_data_filed[0].profile"
                    class="w-14 h-14 p-1 rounded-full border"
                  />
                  <img
                    v-else
                    src="../assets/man.png"
                    class="w-10 p-1 rounded-full border"
                  />
                  <h3 class="text-lg font-semibold capitalize">
                    {{ this.userProfiileInfo[0].display_name }}
                  </h3>
                </router-link>
              </template>
              <template
                v-else-if="this.userInfo.data.user.Permission_access == 'tpo'"
              >
                <router-link
                  :to="{ name: 'tpoDashboard' }"
                  class="flex gap-2 items-center"
                >
                  <img
                    v-if="this.userProfiileInfo[0].user_data_filed[0].profile"
                    :src="this.userProfiileInfo[0].user_data_filed[0].profile"
                    class="w-14 h-14 p-1 rounded-full border"
                  />
                  <img
                    v-else
                    src="../assets/man.png"
                    class="w-10 p-1 rounded-full border"
                  />
                  <h3 class="text-lg font-semibold capitalize">
                    {{ this.userProfiileInfo[0].display_name }}
                  </h3>
                </router-link>
              </template>
              <button
                @click="logOut()"
                class="bg-primary px-4 py-2 rounded-lg font-semibold text-xs"
              >
                Logout
              </button>
            </div>
          </div>
          <button
            class="block md:hidden px-4 py-2 text-black"
            type="button"
            id="menuIcon"
          >
            <i class="fa-solid fa-bars text-3xl"></i>
          </button>
        </div>
      </div>

      <div
        class="hidden md:hidden px-4 py-5 bg-white absolute w-full border-b border-gray-200 shadow-xl"
        id="collapseExample"
      >
        <div
          v-if="!this.isAuthenticated"
          class="flex flex-row items-center justify-center"
        >
          <img src="../assets/whatsapp-icon.png" alt="" width="30" class="mr-2">
                <div class="bg-primary px-4 py-2 rounded-full mr-5 font-semibold text-xs whitespace-nowrap">
                    Call Us @ +91 95945 56845
                </div>
                <router-link v-if="this.isAuthenticated != true" :to="{name:'Login'}">
                <button class="bg-primary px-4 py-2 rounded-lg font-semibold text-xs">
                    Login
                </button>
                </router-link>
          <ul
            v-if="this.isAuthenticated != true"
            class="block md:hidden flex flex-col gap-4 md:gap-7 text-xs md:text-sm cursor-pointer"
          >
            <li @click="scrollPosition('about-content', $event)" class="p-2">
              About us
            </li>
            <li @click="scrollPosition('faq-content', $event)" class="p-2">
              FAQs
            </li>
            <li @click="scrollPosition('enquiry-content', $event)" class="p-2">
              Enquiry
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: mapGetters(["userInfo", "isAuthenticated", "userProfiileInfo"]),
  created() {},
  mounted() {
    document.querySelector("#menuIcon").addEventListener("click", function () {
      document.querySelector("#collapseExample").classList.toggle("hidden");
    });
    // $('.about-btn').on('click',function(){
    //     $('html, body').animate({scrollTop: $('#aboutText').offset().top - 85}, 1000);
    // })
  },
  methods: {
    ...mapActions(["logOutUser"]),
    async logOut() {
      await this.logOutUser();
      if (this.isAuthenticated == false) {
        this.$router.push({ name: "Login" });
      }
    },
    scrollPosition(Val, el) {
      const elements = document.querySelectorAll(".active-btn");
      elements.forEach((element) => {
        element.classList.remove("active-btn");
      });
      el.target.classList.add("active-btn");
      $("html, body").animate(
        { scrollTop: $(`#${Val}`).offset().top - 85 },
        1000
      );
    },
  },
};
</script>

<style scoped>
.active-btn {
  border-bottom: 3px solid #cc3333;
}
</style>
