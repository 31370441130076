<template>
<div class="w-full h-full">
    <Navigation />
    <div class="w-full max-w-7xl mx-auto flex flex-col mt-10 gap-4" style="height:calc(100vh - 80px);"> 
        <div class="max-w-4xl min-w-[360px] min-h-[360px]  border border-gray-300 w-full rounded-md shadow-xl mx-auto flex items-center justify-center">
            <!-- question -->
            <div class="w-full h-full flex items-center justify-center">
                <div v-if="tst == true">
                    You are completed
                    <div class="flex gap-4">
                        <!-- <button class="gradient-btn" @click="ckans">Result</button> -->
                        <!-- <router-link :to="{name:'Describetype'}">
                            <button class="gradient-btn" @click="ckans">Next</button>
                        </router-link> -->
                    </div>
                </div>
                <div v-else class="h-full w-full">
                    <div v-if="questions.length>0" class="w-full h-full flex flex-col justify-center items-center">
                        <div class="w-full h-auto flex flex-col justify-center">
                            <div class="text-center py-2">
                                Question No.{{ currentQuestion + 1 }} of {{ questions.length }}
                            </div>
                            <div class="border-2 border-[#CA2526]" :max="30" :value="countDown"></div>
                            <div style="margin-top: 15px">
                                <span style="font-size: 40px"><strong>{{ Math.floor(countDown/60).toString().padStart(2,'0') }} : {{ (countDown%60).toString().padStart(2,'0') }} </strong></span>
                            </div>
                            <div class="p-4">
                                <p v-html="questions[currentQuestion].question_text"></p>
                            </div>
                            <div class="flex flex-col md:flex-row flex-wrap justify-center gap-4 w-full">
                                <button class="border rounded py-2 px-4 w-[90%] md:w-[45%] mx-auto relative hover:border-[#CA2526]" :class="[questions[currentQuestion].ansclk==option.id ? 'bg-[#CA2526] text-white border-gray-400' : '']" :key="index" v-for="(option, index) in questions[currentQuestion].answer" @click="handleAnswerClick(option.is_correct,option.id)">
                                    {{ option.answer_text }}
                                </button>
                            </div>

                        </div>
                    </div>
                    <div v-else class="flex flex-col justify-center w-full h-full">
                        <div v-html="this.message" class="w-full h-32 flex flex-col justify-center items-center max-h-[358px] text-xl">
                        </div>
                    </div>
                    <div v-if="questions.length>0" class="max-w-4xl min-w-[360px] bg-gray-200">
                        <ul class="flex py-2 px-6 items-center gap-4 overflow-y-auto" :class="slice_limit>10 ? 'justify-start' : 'justify-center'">
                            <div v-if="this.slice_start != 0" 
                                class="border px-2 bg-white rounded hover:bg-[#CA2526] hover:text-white text-xl w-[35px] h-[35px] leading-[40px] cursor-pointer flex items-center" 
                                @click="this.slice_start=this.slice_start-slice_limit,this.slice_end=this.slice_end-slice_limit"> 
                                <img src="../../assets/backward.png" alt=""> </div>
                            <li v-for="(i,index) in questions.slice(this.slice_start,this.slice_end)" :key="i"
                                class="border px-2 bg-white rounded hover:bg-[#CA2526] hover:text-white align-middle text-sm w-[35px] h-[35px] leading-[40px] cursor-pointer" 
                                :class="[currentQuestion==index+this.slice_start ? 'border-[#CA2526]' : '']" @click="currentQuestion=index+this.slice_start">{{index +this.slice_start+1}}</li>
                            <div v-if="this.slice_end<questions.length" 
                                class="border px-2 bg-white rounded hover:bg-[#CA2526] hover:text-white align-middle text-sm w-[35px] h-[35px] leading-[40px] cursor-pointer flex items-center" 
                                @click="this.slice_start=this.slice_start+slice_limit,this.slice_end=this.slice_end+slice_limit"> 
                                <img src="../../assets/fast-forward.png" alt="">
                            </div>
                        </ul>
                    </div>
                    <div v-if="questions.length>0" class="my-4 flex gap-4 justify-center">
                        <button v-if="currentQuestion > 0" class="gradient-btn" @click="pvsQt"> Previous</button>
                        <button v-if="currentQuestion+1 < this.questions.length" class="gradient-btn" @click="nxtQt">Next</button>
                        <button v-if="currentQuestion+1 == this.questions.length" @click="ckans" class="gradient-btn"> Submit</button>
                    </div>
                    <div v-else class="py-4">
                        <router-link  :to="{name:'DashboardView'}">
                        <button class="gradient-btn" > Home</button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import axios from 'axios';
import {
    mapActions,
    mapGetters
} from "vuex"
export default {
    name: "McqView.vue",
    data() {
        return {
            currentQuestion: 0,
            countDown: 840,
            showScore: false,
            questions: [],
            tst: false,
            score: 0,
            is_completed: false,
            is_submit : false,
            nextquz:0,
            message : '',
            mcqset:'',
            paginator_limit:6,
            slice_start : 0,
            slice_end : 0,
            slice_limit : 10,
        }
    },
    computed: mapGetters(["userInfo","userProfiileInfo"]),
    components: {
        Navigation,
    },
    async created() {
        this.slice_end = this.slice_limit
        
        this.mcqCall()
        this.tst = false
        this.countDownTimer()
        

    },
    
    methods: {
        async mcqCall(){
            await axios.get(`${process.env.VUE_APP_API}/quiz-all-details/list/`)
            .then(r => {
                if(r.data.status == 200){
                    this.mcqset = r.data.data[0]
                    this.questions = r.data.data[0].question
                    this.countDown= parseInt(this.mcqset.duration)*60
                }
                else {
                    this.questions = []
                    this.message = r.data.message
                }
                if (this.questions) {
                    this.questions.forEach(el => {
                        el.ansclk = '';
                        el.anr = false
                    })
                }
            })
        },

        handleAnswerClick(isCorrect, id) {
            
            this.questions[this.currentQuestion].ansclk = id
            this.questions[this.currentQuestion].anr = isCorrect
            let nextQuestion = this.currentQuestion + 1;
            if(nextQuestion+1>this.slice_end && this.slice_end<this.questions.length){
                this.slice_start=this.slice_start+this.slice_limit
                this.slice_end=this.slice_end+this.slice_limit
            }
            
            if (nextQuestion < this.questions.length) {
                this.currentQuestion = nextQuestion;
            }
        },
        countDownTimer() {
            if (this.countDown > 0) {
                this.timer = setTimeout(() => {
                    this.countDown -= 1;
                    this.countDownTimer();
                }, 1000);
            } 
        },
        submitMcq(){
            this.showScore = true;
                localStorage.setItem('mcqTstv', true);
                this.tst = true;
        },
        nxtQt() {
            let nextQuestion = this.currentQuestion + 1;
            if (nextQuestion < this.questions.length) {
                this.currentQuestion = nextQuestion;
            } 

        },
        pvsQt() {
            if (this.currentQuestion > 0) {
                let nextQuestion = this.currentQuestion - 1;
                if (nextQuestion => 0) {
                    this.currentQuestion = nextQuestion;
                }
            }
        },
        async ckans() {
            var ans_set = [] 
            this.questions.forEach(el => {
                let val = {
                    ques : el.id,
                    ans :el.ansclk
                }
                ans_set.push(val)
                if (el.anr) {
                    this.score += 1
                }
            })
            var headerbody = {
                user : this.userInfo.data.user.id,
                quiz : this.mcqset.id,
                score : this.score,
                completed : true,
                ansVal : ans_set
            }
            let percentage = (this.score/this.mcqset.question.length)*100
            console.log(percentage)

            var grade = percentage>95 ? 'A' : percentage>40 && percentage<=95 ? 'B' : percentage>10 && percentage<=40 ? 'C' : 'D'

            var reservation_id = ''
            if(grade){
                await axios.get(`${process.env.VUE_APP_API}/reservation/list/?grade=${grade}`)
                .then(resp=>{
                    if(resp.data.status==200){
                        reservation_id = resp.data.data[0].id
                    }else{
                        reservation_id = ''
                    }
                })
                var seatJson = {
                    user:this.userInfo.data.user.id,
                    flag: this.userProfiileInfo[0].lead_type=='verified' ? 'GEN' : 'CP',
                    reservation: reservation_id
                }
                console.log(seatJson)
                axios.post(`${process.env.VUE_APP_API}/reservation-booking/post/`,seatJson)
                .then(r=>{
                    console.log(r)
                })
            }
            
           await axios.post(`${process.env.VUE_APP_API}/quiz-taker-details/post/`,headerbody)
            .then(r=>{
                console.log(r)
            })
            this.questions = 0
            this.message = '<span class="font-semibold text-3xl py-1"> Congratulations </span> <br> <span class="py-1"> Your test is now complete! </span> <br> <span class="py-1"> Please click on the home button to view your results </span>'
            
        }
    }
};
</script>

<style src="@/assets/CSS/ProgressTracking.css">
    
</style>
