<template lang="">
    <div class="w-full h-full bg-gray-100">
        <!-- <Navigation /> -->
        <div class="flex w-full h-full min-h-screen justify-center max-w-7xl mx-auto">
            <div class="flex flex-col lg:flex-row min-h-screen w-full">
              <div class="hidden lg:flex flex-col relative w-full lg:w-[50%] h-full bg-[#ffffff] justify-center items-center gap-10">
                <div>
                  <h1 class="uppercase text-3xl font-bold"><span class="text-primary">Vignan Online</span> <br>test for <br>scholarship</h1>
                </div>
                <img src="../assets/image.png" class="p-4 min-w-[300px] lg:max-w-[300px] 2xl:max-w-[400px]" />
              </div>
              <div class="relative w-full lg:w-[50%] h-full bg-white md:bg-[#D2262C] px-3 flex flex-col justify-center items-center gap-16 text-start">
                  <img src="../assets/logo-reverse.png" alt="" class="w-[274px]">
                      <div v-if="optvrf=='login'" class="bg-[#fff] rounded-md px-4 md:px-8 py-6 min-w-[350px] max-w-[400px] shadow-lg border border-gray-200">
                        <form @submit.prevent="sntotp" class="flex flex-col gap-4">
                        <div class="flex items-center gap-x-2">
                            <h3 class="red_underline relative text-black text-base font-medium">Login</h3>
                            <img src="../assets/login.png" alt="">
                        </div>
                        <label for="" class="text-black text-sm mt-6 block font-medium">
                            Enter your registered Email ID
                        </label>
                        <input type="text" placeholder="Enter the Mail id" value="" name="email" id="stEmail"
                            class="mb-4 text-sm w-full bg-white px-0 py-1.5 border-b-2 border-gray-200 border-x-0 border-t-0 focus:border-b-2 focus:border-primary focus:ring-0 focus:outline-none" required>
                            <div class="flex justify-between">
                              <button class="bg-primary text-white font-medium rounded-md text-center px-5 py-2 text-xs" type="submit">
                                  <span v-if="!is_ld"> Get OTP </span>
                                  <span v-else>Loading...</span>
                              </button>
                                <!-- <router-link :to="{name:'Register',params:{slug:'6a0d377c-4f8a-4623-b106-36923f95d6bb'}}" >
                              <button class="bg-primary text-black font-medium rounded-md text-center px-5 py-2 text-xs" type="button">
                                  <span> Register </span>
                              </button>
                                </router-link> -->
                            </div>
                            <div>
                              <p class="text-sm">Don't have an account yet? 
                                <router-link :to="{name:'Register',params:{slug:'6a0d377c-4f8a-4623-b106-36923f95d6bb'}}" >
                                  <span class="text-sm underline text-blue-700"> Register </span>
                                </router-link>
                              </p>
                            </div>
                        <div v-if="this.message != ''" class="mt-5">
                            <div class="py-1 px-3 bg-[#ff0000] font-light text-white text-xs text-center inline-block">
                                Email not found
                            </div>
                            <div class="text-black text-sm font-normal mt-3">Enter your registered student email</div>
                        </div>
                        </form>
                      </div>
              <!-- Otp submit -->
                      <div v-else class="bg-[#fff] rounded-md px-8 py-6 min-w-[350px] w-[400px] shadow-lg border border-gray-200">
                        <div class="flex items-center gap-x-2">
                            <strong class="red_underline relative text-black text-base font-medium">Enter the code sent to
                                your Email ID </strong>
                        </div>
                        <form @submit.prevent="checkOtp">
                        <label for="" class="text-black font-medium text-sm mt-6 block">
                            Enter OTP
                        </label>

                        <div class="otp_field mt-1 mb-5 w-full text-sm flex items-center gap-2">
                            <input @keyup="this.handleOtp" @paste="this.handleOtpPasted" type="text" data_index='0' ref="input1" maxlength="1" placeholder="0"
                                class="bg-white w-[45px] p-2 text-center border-b-2 border-gray-200 border-x-0 border-t-0 focus:border-b-2 focus:border-primary focus:ring-0 focus:outline-none">
                            <input @keyup="this.handleOtp" @paste="this.handleOtpPasted" type="text" data_index='1' maxlength="1" placeholder="0"
                                class="bg-white w-[45px] p-2 text-center border-b-2 border-gray-200 border-x-0 border-t-0 focus:border-b-2 focus:border-primary focus:ring-0 focus:outline-none">
                            <input @keyup="this.handleOtp" @paste="this.handleOtpPasted" type="text" data_index='2' maxlength="1" placeholder="0"
                                class="bg-white w-[45px] p-2 text-center border-b-2 border-gray-200 border-x-0 border-t-0 focus:border-b-2 focus:border-primary focus:ring-0 focus:outline-none">
                            <input @keyup="this.handleOtp" @paste="this.handleOtpPasted" type="text" data_index='3' maxlength="1" placeholder="0"
                                class="bg-white w-[45px] p-2 text-center border-b-2 border-gray-200 border-x-0 border-t-0 focus:border-b-2 focus:border-primary focus:ring-0 focus:outline-none">
                            <input @keyup="this.handleOtp" @paste="this.handleOtpPasted" type="text" data_index='4' maxlength="1" placeholder="0"
                                class="bg-white w-[45px] p-2 text-center border-b-2 border-gray-200 border-x-0 border-t-0 focus:border-b-2 focus:border-primary focus:ring-0 focus:outline-none">
                            <input @keyup="this.handleOtp" @paste="this.handleOtpPasted" type="text" data_index='5' maxlength="1" placeholder="0"
                                class="bg-white w-[45px] p-2 text-center border-b-2 border-gray-200 border-x-0 border-t-0 focus:border-b-2 focus:border-primary focus:ring-0 focus:outline-none">
                        </div>

                        <button v-if="this.ld_btn != true"
                            class="submit bg-primary text-white font-medium rounded-md text-center px-5 py-2 text-xs uppercase">
                            Submit
                        </button>
                        <button v-else 
                            class="submit bg-primary text-black font-medium rounded-md text-center px-5 py-2 text-xs uppercase">
                            Loading..
                        </button>
                        </form>
                        <span v-if="this.message != ''" class="text-sm text-red-400 font-medium px-4 ml-2"> {{ this.message }} </span>

                        <div class="text-gray-600 text-xs font-normal mt-5">
                            *Your OTP expires in <span class="timer">05:00</span>
                        </div>
                      </div>
              <div class="block md:hidden">
                <div>
                  <img src="../assets/lots-new-logo3.png"  class="h-24" />
                </div>
              </div>
            <div class="absolute bottom-3 md:bottom-5 text-white text-xs">
                &copy; Copyright Learn.Online. All Right Reserved.
            </div>
        </div>
    </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Navigation from "../components/Navigation.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      optvrf: "login",
      is_ld: false,
      ld_btn: false,
      message: "",
      loginError: "",
      mailId: "",
      inputs: "",
      startMinutes_cst: "",
    };
  },
  components: {
    Navigation,
  },
  computed: mapGetters(["userInfo", "isAuthenticated"]),
  mounted() {},
  methods: {
    ...mapActions(["loginUser", "userDetailsGet"]),

    startTimer(startMinutes) {
      var time = startMinutes * 60;

      this.startMinutes_cst = setInterval(function () {
        var minutes = Math.floor(time / 60);
        var seconds = time % 60;

        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        var result = `${minutes}:${seconds}`;
        document.querySelector(".timer").innerHTML = result;

        time--;

        if (time < 0) {
          clearInterval(t);
          document.querySelector(".timer").innerHTML = "expired...";
        }
      }, 1000);
    },

    handleOtpPasted(event) {
      var clipboardData = event.clipboardData || window.clipboardData;
      var pastedData = clipboardData.getData("text");
      var trimPastedData = pastedData.replaceAll(" ", "");
      this.inputs = document.querySelectorAll(".otp_field input");
      if (trimPastedData.length > 0) {
        var pastedDataList = trimPastedData.split("");
        if (
          Array.isArray(pastedDataList) &&
          this.onlyNumbers(pastedDataList) &&
          pastedDataList.length == this.inputs.length
        ) {
          this.inputs.forEach((input, index) => {
            input.value = pastedDataList[index];
          });
          this.inputs[this.inputs.length - 1].focus();
        } else {
          event.preventDefault();
        }
      }
    },

    handleOtp(event) {
      var dataIndex = parseInt(event.target.getAttribute("data_index"));
      this.inputs = document.querySelectorAll(".otp_field input");
      if (dataIndex != 0 && event.keyCode == 8) {
        this.inputs[dataIndex - 1].focus();
      } else if (
        dataIndex != this.inputs.length - 1 &&
        event.target.value.trim().length > 0
      ) {
        this.inputs[dataIndex + 1].focus();
      }
    },

    onlyNumbers(list) {
      var isValidNumber = list.every((item) => !isNaN(item));
      return isValidNumber;
    },

    getOtp() {
      var otp = "";
      this.inputs.forEach((input) => (otp += input.value));
      return otp;
    },

    sntotp(el) {
      this.is_ld = true;
      let mailId = el.target.elements.stEmail.value;
      let hdrVal = {
        email: mailId,
      };
      console.log("send otp");
      axios.post(`${process.env.VUE_APP_API}/login/`, hdrVal).then((resp) => {
        console.log(resp);
        if (resp.data.status == 200) {
          this.is_ld = false;
          this.optvrf = "optvrf";
          this.mailId = el.target.elements.stEmail.value;
          setTimeout(()=>{
            this.$refs.input1.focus();
          },1000)
          setTimeout(() => {
            this.startTimer(5);
          }, 3000);
        } else {
          this.is_ld = false;
          this.message = "Email id not found";
        }
      });
      setTimeout(() => {
        this.message = "";
      }, 3000);
    },
    async checkOtp() {
      console.log("cehck");
      this.ld_btn = true;
      let hdrVal = {
        user_email: this.mailId,
        entered_otp: this.getOtp(),
      };
      await this.loginUser(hdrVal);
      if (this.isAuthenticated == true) {
        console.log(this.userInfo.data.user, "user info");
        await this.userDetailsGet(this.userInfo.data.token);
        clearInterval(this.startMinutes_cst);
        if (this.userInfo.data.user.Permission_access == "cp") {
          this.$router.push({ name: "cpDashboard" });
        } else if (this.userInfo.data.user.Permission_access == "tpo") {
          this.$router.push({ name: "tpoDashboard" });
        } else if (this.userInfo.data.user.Permission_access == "admin") {
          this.$router.push({ name: "adminDashboard" });
        } else if (this.userInfo.data.user.Permission_access == "ist") {
          this.$router.push({ name: "istDashboard" });
        } else if (this.userInfo.data.user.Permission_access == "student") {
          this.$router.push({ name: "DashboardView" });
        }
      } else {
        this.message = "Invalid OTP";
      }
      setTimeout(() => {
        this.ld_btn = false;
        this.message = "";
      }, 3000);
    },
  },
};
</script>
<style lang="">
</style>